* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&family=Open+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Open+Sans:wght@400;500;600;700&display=swap");

.openModal {
  display: none;
}

.technologyCardHeader:hover .technologyCard {
  display: block;
}

.technologyCardHeader {
  p {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.technologyCard {
  display: none;
  position: absolute;
  max-width: 258px;
  width: 100%;
  background: none;
  z-index: 1;
  color: #ffffff;
  background: #ffffff;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
  button {
    font-size: 16px;
    text-align: start;
    background: none;
    padding: 10px;
    width: 100%;
    &:hover {
      background: #f49f0a;
      color: #fff;
    }
  }
}

.body {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff !important;
  padding: 70px !important;
}
.body1 {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.relative {
  padding: 170px 0 50px 0;
  background-image: url("../public/img/Video_Still.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.relative1 {
  padding: 170px 0 50px 0;
}
.container {
  background: url("../public/img/Video_Still.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// HEADER
.container-header {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  background: #f7f7f7;
  .body-header {
    max-width: 1450px;
    width: 100%;
    margin: 0 auto;
    .header {
      padding: 10px 0;
      .header-logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        a {
          display: flex;
          align-items: center;
          gap: 20px;
          img {
            max-width: 100px;
            width: 100%;
            height: 100px;
          }
          h3 {
            font-weight: 500;
            font-family: "Arimo", sans-serif;
            color: #459b2b;
            font-style: italic;
            font-size: 32px;
          }
        }
        .nav-link {
          display: flex;
          align-items: center;
          gap: 20px;

          p {
            font-family: "Arimo", sans-serif;
            font-weight: 500;
            color: black;
            font-size: 16px;
            transition: all 0.5s ease;
            padding: 10px 7px;

            &:hover {
              border-radius: 5px;
              background: #f49f0a;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

// CARD

.card {
  .card-text {
    margin-bottom: 40px;
    h2 {
      color: #fff;
      font-family: "Arimo", sans-serif;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
    }
  }
  .cards-card {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 60px;
    position: relative;
    .cards-card__One {
      max-width: 394px;
      width: 100%;
      background: lavender;
      img {
        max-width: 394px;
        width: 100%;
        min-height: 290px;
      }
      h4 {
        height: 60px;
        font-family: "Arimo", sans-serif;
        font-weight: 400;
        line-height: 30px;
        font-size: 18px;
        color: #fff;
      }
      button {
        cursor: pointer;
        border: 2px solid #fff;
        background: transparent;
        padding: 5px 20px;
        color: #fff;
        transition: all 0.5s ease;
        margin-top: 30px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.2196078431372549);
        }
      }
      .card-bottom {
        height: 170px;
        padding: 25px 25px 25px 25px;
        background-color: #f49f0a;
      }
      .card-bottom-one {
        height: 170px;
        padding: 25px 25px 25px 25px;
        background-color: #067bc2;
      }
    }
  }
}

// CONTACT
.contact-page {
  h1 {
    font-family: "Arimo", sans-serif;
    font-weight: 600;
    color: #54595f;
    font-size: 32px;
    margin-bottom: 20px;
  }
  .contact {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    h3 {
      font-family: "Arimo", sans-serif;
      font-size: 24px;
      font-weight: 600;
      color: #54595f;
    }
    .contact-address {
      p {
        font-family: "Arimo", sans-serif;
        line-height: 25px;
        font-weight: 400;
        font-size: 18px;
        color: #3a3a3a;
        a {
          color: blue;
        }
      }
    }
    .contact-input {
      .contact-inputFlex {
        display: flex;
        gap: 20px;
        margin: 20px 0;
      }
      .contact-inputFlexInput {
        width: 300px;
      }
      .inputTextarea {
        height: 100px;
        width: 620px;
      }
      input {
        font-size: 16px;
        padding: 10px 10px;
        max-width: 300px;
        width: 100%;
        border: 1px solid #54595f;
      }

      textarea {
        padding: 10px 10px;
        max-width: 620px;
        width: 100%;
        border: 1px solid #54595f;
      }
      .button-contact {
        margin-top: 20px;
        button {
          font-size: 18px;
          color: #fff;
          background: #f6a11a;
          border-radius: 5px;
          padding: 10px 40px;
          margin-right: 10px;
        }
      }
      p {
        font-size: 16px;
        max-width: 410px;
        width: 100%;
        margin: 30px auto;
        text-align: center;
        a {
          color: blue;
        }
      }
    }
  }
}

// COMPANY
.company {
  .company-text {
    background: #f49f0a;
    margin-bottom: 50px;
    h3 {
      font-family: "Arimo", sans-serif;
      font-size: 32px;
      font-weight: 600;
      padding: 20px 0;
      text-align: center;
      color: #fff;
    }
  }
  .company-product {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin: 0 0 50px 0;
    img {
      width: 400px;
    }
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #3a3a3a;
    }
    span {
      font-family: "Arimo", sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: black;
      color: #3a3a3a;
    }
  }
  .companyProduct-solutions {
    h2 {
      font-weight: 500;
      font-size: 32px;
      color: #54595f;
      margin: 50px 0 20px 0;
    }
  }
  .company-icon {
    background: red;
  }
  .company-leader {
    margin: 50px 0 50px 0;
    display: flex;
    gap: 30px;
    img {
      width: 250px;
      height: 220px;
    }
    .company-leader-text {
      p {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 18px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .company-competences {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin: 70px 0;
    h3 {
      font-family: "Arimo", sans-serif;
      font-weight: 500;
      color: #54595f;
      text-align: center;
      font-size: 32px;
      margin-bottom: 20px;
    }
    .company-competences__group {
      .company-competences__text {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 30px;
        p {
          font-weight: "Open Sans", sans-serif;
          font-weight: 400;
          line-height: 25px;
          font-size: 18px;
        }
      }
    }
  }
  .company-partners {
    h3 {
      font-family: "Arimo", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 32px;
      margin-bottom: 10px;
    }
    .partner-advertising {
      display: flex;
      justify-content: space-between;
      gap: 50px;
      margin: 30px 0 50px 0;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        line-height: 25px;
        font-size: 18px;
      }
    }
  }
}

// CARDGRAIN
.card {
  .texth3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #54595f;
    font-size: 26px;
    text-align: center;
    line-height: 1.2em;
    margin: 50px 0 20px 0;
  }
  .text-h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #54595f;
    font-size: 26px;
    line-height: 1.2em;
    margin: 50px 0 20px 0;
  }
  .text_h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #54595f;
    font-size: 26px;
    line-height: 1.2em;
    margin: 20px 0 20px 0;
  }
  .text__h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #54595f;
    font-size: 26px;
    text-align: center;
    line-height: 1.2em;
    margin: 20px 0 20px 0;
  }
  .cardTick {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 40px;

    img {
      width: 30px;
      height: 30px;
    }
    p {
      font-family: "Arimo", sans-serif;
      font-weight: 700;
      font-size: 30px;
      color: red;
    }
  }
  .cardTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7a11a;
    max-width: 800px;
    margin: 40px auto;
    width: 100%;
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      padding: 15px 0px;
      color: #fff;
      max-width: 780px;
      width: 100%;
      text-align: center;
    }
  }
  table,
  th,
  td {
    border-collapse: collapse;
    padding: 10px 10px 10px 10px;
  }
  th {
    text-align: left;
    background: #067bc2;
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat", "sans-serif";
    line-height: 1;
    color: #fff;
    border: 1px solid #fff;
    border-width: 2px;
  }
  td {
    font-size: 14px;
    font-family: "Montserrat", "sans-serif";
    font-weight: 600;
    line-height: 1;
    border: 1px solid #fff;
    border-width: 2px;
    background-clip: padding-box;
    background: #f49f0a;
  }
  .mainImg {
    img {
      width: 100%;
      height: 450px;
      object-fit: cover;
    }
    h3 {
      font-family: "Arimo", sans-serif;
      font-size: 32px;
      font-weight: 600;
      padding: 20px 0;
      text-align: center;
      color: #fff;
      background: #067bc2;
      margin-bottom: 50px;
    }
  }
  .cardBiosolutions {
    table,
    th,
    td {
      border-collapse: collapse;
      padding: 10px 10px 10px 10px;
    }
    th {
      text-align: left;
      background: #067bc2;
      font-size: 16px;
      font-weight: 600;
      font-family: "Montserrat", "sans-serif";
      line-height: 1;
      color: #fff;
      border: 1px solid #fff;
      border-width: 2px;
    }
    td {
      font-size: 14px;
      font-family: "Montserrat", "sans-serif";
      font-weight: 600;
      line-height: 1;
      border: 1px solid #fff;
      border-width: 2px;
      background-clip: padding-box;
      background: #f49f0a;
    }
    .cardTick {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      img {
        width: 30px;
        height: 30px;
      }
      p {
        font-family: "Arimo", sans-serif;
        font-weight: 700;
        font-size: 30px;
      }
    }

    .cardsGrain {
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      .cardsCard {
        min-height: 511px;
        height: 100%;
        max-width: 240px;
        width: 100%;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 10px;
        img {
          max-width: 208px;
          width: 100%;
          margin-bottom: 10px;
        }
        p {
          font-family: "Open Sans", sans-serif;
          line-height: 1.4em;
          font-weight: 500;
          font-size: 14px;
          color: gray;
          margin-bottom: 25px;
        }
        h4 {
          line-height: 1.4em;
          font-family: "Open Sans", sans-serif;
          font-weight: 600;
          font-size: 14px;
          color: black;
        }
        h5 {
          line-height: 1.4em;
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          font-size: 14px;
          color: black;
          margin-bottom: 30px;
        }
        span {
          line-height: 1.4em;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #3a3a3a;
        }
      }
    }
    .grainProcessing {
      display: flex;
      justify-content: space-between;
      margin: 70px 0px;
      gap: 100px;
      .grainCapability {
        max-width: 472px;
        width: 100%;
        .processinLine {
          border: 1px solid red;
          background: red;
        }
        h4 {
          color: #54595f;
          font-size: 18px;
          font-weight: 600;
          text-transform: uppercase;
          margin: 30px 0 20px 0;
        }
        p {
          line-height: 30px;
          font-size: 16px;
        }
        .capabilityCard {
          background: #f49f0a;
          border-radius: 5px;
          padding: 25px 25px;
          margin-top: 40px;
          p {
            color: #fff;
            line-height: 30px;
            font-size: 18px;
          }
        }
      }
    }

    .tableLine {
      border: 1px solid green;
      margin-bottom: 20px;
    }
    .mainGrainProducts {
      display: flex;
      // justify-content: space-between;
      // align-items: center;
      gap: 20px;
      .grainProductsImg {
        img {
          max-width: 263px;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
    .tableHoney {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
    }
    .recylingImage {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 50px 0 0px 0px;
      img {
        height: 501px;
      }
    }
    .recyclingPage {
      margin-bottom: 50px;

      .recylingText {
        display: flex;
        align-items: center;
        .recylingTextRed {
          background: #ee3a43;
          padding: 10px 100px 10px 10px;
          h4 {
            color: #fff;
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 5px;
          }
          p {
            color: #fff;
            font-size: 26px;
          }
        }
        .recylingTextGreen {
          background: #48aa43;
          padding: 6px 94px 6px 6px;
          p {
            color: #fff;
            font-size: 26px;
            text-transform: uppercase;
            line-height: 1.3em;
          }
        }
      }
    }

    .balanceCorn {
      margin-bottom: 120px;
    }
  }
  .sorbitolProduction {
    .sorbitolCard {
      margin-top: 20px;
      h3 {
        font-family: "Arimo", sans-serif;
        font-weight: 400;
        line-height: 35px;
        font-size: 18px;
        margin: 0;
      }
      padding: 25px 25px 5px 25px;
      background-color: transparent;
      background-image: linear-gradient(180deg, #b7ddb5 0%, #ffffff 100%);
      border-radius: 3px 3px 3px 3px;
    }
  }
  .biotechnologicalPage {
    .biotechnologicalCard {
      margin-top: 20px;
      h3 {
        font-family: "Arimo", sans-serif;
        font-weight: 400;
        line-height: 35px;
        font-size: 18px;
        margin: 0;
      }
      padding: 25px 25px 5px 25px;
      background-color: transparent;
      background-image: linear-gradient(180deg, #b7ddb5 0%, #ffffff 100%);
      border-radius: 3px 3px 3px 3px;
    }
  }
  .fermentPage {
    margin-bottom: 40px;
    h4 {
      font-size: 24px;
      line-height: 1.2em;
      margin: 20px 0;
    }
    .fermentTable {
      table:first-child {
        margin-bottom: 20px;
      }
    }
  }
  .healthPage {
    margin: 50px 0;
    ul {
      li {
        list-style: disc;
      }
    }
    .healthFlexPage {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 40px;
      p {
        font-family: "Open Sans", sans-serif;
        color: #3a3a3a;
        line-height: 1.3em;
        font-size: 22px;
        margin: 20px 0;
      }
      ul {
        li {
          font-family: "Open Sans", sans-serif;
          color: #3a3a3a;
          line-height: 1.3em;
          font-size: 22px;
          margin-left: 70px;
        }
      }
      img {
        margin-bottom: 10px;
      }
      .healthUrlCard {
        border: 3px solid #48aa43;
        padding: 20px 20px;
        margin-top: 20px;
        p {
          font-family: "Open Sans", sans-serif;
          color: #3a3a3a;
          font-weight: 700;
          font-size: 13px;
          margin: 0 0 15px 0;
        }
      }
    }
    .healthPageCard {
      background: #48aa43;
      padding: 20px;
      p {
        font-family: "Open Sans" sans-serif;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        line-height: 1.2em;
      }
      span {
        font-family: "Open Sans" sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        line-height: 1.2em;
      }
    }
  }
  .aminoAcids {
    margin: 50px 0;
    p {
      font-family: "Open Sans" sans-serif;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      margin: 20px 0;
    }
    .aminoAcidsCard {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    li {
      margin-bottom: 10px;
      font-family: "Open Sans" sans-serif;
      font-size: 16px;
      line-height: 1.3em;
      font-weight: 600;
    }
  }
  .ul {
    margin: 50px 0;
    li {
      margin-bottom: 10px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 16px;
      list-style: decimal;
    }
  }
  .glutenPage {
    .glutenPageCard {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 20px;
      img {
        width: 157px;
        height: 109px;
      }
      p {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 600;
      }
      span {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .floatRight {
      text-align: right;
      margin-bottom: 50px;
      h4 {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5em;
        font-weight: 600;
      }
      h5 {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5em;
        color: red;
      }
    }
  }
  .bioTasks {
    p {
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 400;
      color: #3a3a3a;
      line-height: 1.4em;
      margin: 40px 0 -20px 0;
    }
    .li {
      margin: 40px 0 40px 40px;
      li {
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 400;
        color: #3a3a3a;
        line-height: 1.4em;
      }
    }
    .bioTasksButton {
      display: flex;
      justify-content: center;
      button {
        color: #ffffff;
        padding: 15px 30px;
        background: #f49f0a;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .industrialPage {
    p {
      font-size: 16px;
      line-height: 1.8em;
      color: #3a3a3a;
      margin: 20px 0;
    }
    .industrialCard {
      max-width: 880px;
      width: 100%;
      margin: 40px auto;
      padding: 5px 5px 5px 5px;
      background-color: #cfe2ff;
      p {
        font-size: 26px;
        text-align: center;
        line-height: 25px;
      }
    }
    .fermentTable {
      max-width: 880px;
      width: 100%;
      margin: 0 auto;
      table {
        width: 100%;
      }
    }
  }
  .glikoGag {
    .glikoGagCard {
      display: flex;
      justify-content: space-between;
      margin: 70px 0;
      gap: 30px;
      p {
        font-family: "Open Sans", sans-serif;
        color: #3a3a3a;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.4em;
        margin-bottom: 20px;
      }
      span {
        font-family: "Open Sans", sans-serif;
        color: #3a3a3a;
        font-size: 18px;
        font-weight: 400;
      }
      .glikoCard {
        padding: 20px 20px 1px 20px;
        background: #47aa42;
        p {
          font-family: "open Sans", sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: #fff;
          line-height: 1.5em;
          margin-bottom: 20px;
        }
      }
    }
  }
  .gagExtraction {
    .gagExtractionMain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0 70px 0;
      gap: 30px;
      .gagExtractionLeft {
        h5 {
          font-size: 18px;
          font-weight: 600;
        }
        p {
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          line-height: 1.4em;
          margin: 20px 0;
        }
        .gagExtractionLeftCard {
          background: #f49f0a;
          padding: 20px;
          h4 {
            font-family: "Open Sans", sans-serif;
            font-size: 18px;
            font-weight: 700;
            color: #fff;
            line-height: 1.4em;
            margin-bottom: 20px;
          }
          ul {
            max-width: 700px;
            width: 100%;
            margin: 0 auto;
            li {
              list-style: disc;
              font-size: 18px;
              color: #fff;
              font-weight: 400;
              line-height: 1.6em;
            }
          }
        }
      }
      img {
        border-radius: 10px;
      }
    }
  }
  .generalSchemeHeparin {
    padding: 70px 0;
    .generalShemeCards {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      .generalShemeCard {
        align-items: center;
        margin: 20px 0;
        img {
          width: 275px;
          height: 234px;
        }
        h4 {
          font-family: "Open Sans", sans-serif;
          font-weight: 600;
          font-size: 18px;
          margin: 20px 0;
        }
        ul {
          li {
            list-style: disc;
            font-family: "Open Sans", sans-serif;
            font-size: 18px;
            line-height: 1.4em;
            color: grey;
          }
        }
      }
    }
  }
  .bioTasksPharm {
    .li {
      margin: 30px 0;
      p {
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 600;
        color: #3a3a3a;
        line-height: 1.4em;
        margin-bottom: 10px;
      }
      li {
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 400;
        color: #3a3a3a;
        line-height: 1.4em;
        margin-bottom: 10px;
      }
    }
    .bioTasksButton {
      display: flex;
      justify-content: center;
      button {
        color: #ffffff;
        padding: 15px 30px;
        background: #f49f0a;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .biologicalPropertiesPage {
    margin: 50px 0;
    p {
      font-size: 18px;
      margin: 20px 0 4px 0;
    }
    h5 {
      font-family: "Open Sans" sans-serif;
      font-weight: 600;
      font-size: 20px;
      color: black;
      margin-bottom: 4px;
    }
    a {
      font-family: "Open Sans" sans-serif;
      font-weight: 400;
      font-size: 18px;
      color: black;
    }
    .bioPropertiesFlex {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .firstProperties {
        max-width: 265px;
        width: 100%;
        padding: 15px;
        background: #ffce78;
        p {
          font-family: "Open Sans" sans-serif;
          font-size: 22px;
          line-height: 1em;
          font-weight: 600;
          margin: 0;
        }
        ul {
          margin: 20px 0;
          text-align: center;
          li {
            font-family: "Open Sans" sans-serif;
            font-size: 22px;
            line-height: 1em;
            font-weight: 600;
          }
        }
      }
    }
  }
  .spirt {
    .spirtText {
      display: grid;
      justify-content: center;
    }
    .spirtImg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 100px 0;

      img {
        max-width: 792px;
        width: 100%;
        height: 447px;
      }
      p {
        font-size: 18px;
        line-height: 1.8em;
      }
    }
    .processDiagram {
      .processDiagramCards {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        .processDiagramCard {
          background: #f49f0a;
          padding: 25px;
          width: 490px;
          height: 225px;
          p {
            font-family: "Open Sans", sans-serif;
            font-size: 18px;
            color: #fff;
            line-height: 1.4em;
          }
        }
      }
    }
  }
  .spirtProduction {
    margin: 150px 0 80px 0;
    p {
      font-family: "Open Sans", sans-serif;
      color: #000;
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      line-height: 1.2em;
      margin: 10px 0;
      span {
        font-family: "Open Sans", sans-serif;
        color: red;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        line-height: 1.2em;
        margin: 10px 0;
      }
    }
  }
  .alcoholPreparation {
    margin: 60px 0;
    .alcoholPreparationImg {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin: 40px 0;
      align-items: center;
    }
    p {
      font-family: "Open Sans", sans-serif;
      color: #000;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      line-height: 1.2em;
      margin: 10px 0;
    }
    .incraseReduction {
      display: flex;
      justify-content: space-between;
      h5 {
        font-family: "Open Sans", sans-serif;
        color: #70bc6c;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.2em;
        margin: 10px 0;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        color: grey;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2em;
        margin: 10px 0;
      }
      ul {
        li {
          list-style: disc;
          font-family: "Open Sans", sans-serif;
          color: grey;
          font-size: 16px;
          line-height: 1.7em;
        }
      }
    }
  }
  .alcoholFermentation {
    padding: 100px 0;
    .alcoholFermentationCard {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin: 30px 0;
      img {
        max-width: 354px;
        width: 100%;
        height: 229px;
      }
      h4 {
        color: #0056d6;
        font-size: 26px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      p {
        color: #ee3a43;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2em;
        span {
          color: grey;
          font-size: 18px;
          font-weight: 500;
          line-height: 1.2em;
          a {
            color: #0056d6;
            font-size: 18px;
          }
        }
      }
      h5 {
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        color: grey;
        line-height: 1.4em;
        margin: 20px 0;
      }
    }
  }
  .engineeringEquipment {
    margin: 70px 0;

    .engineeringEquipmentCard {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      gap: 30px;
      .engineeringEquipmentCardImg {
        max-width: 334px;
        width: 100%;
        height: 522px;
      }
      p {
        font-family: "Open Sans", sans-serif;
        color: grey;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5em;
        span {
          font-family: "Open Sans", sans-serif;
          color: #000;
          font-size: 18px;
          font-weight: 700;
          line-height: 1.5em;
        }
      }
      img {
        max-width: 806px;
        width: 100%;
        height: 379px;
      }
    }
  }
  .chickenProteinInformation {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 50px;
    img {
      max-width: 444px;
      width: 100%;
      height: 287px;
    }
    .chickenProteinInformationLeft {
      max-width: 444px;
      width: 100%;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 15px;
        color: #3a3a3a;
        line-height: 1.5em;
        padding: 15px 15px;
      }
    }
    .chickenProteinInformationRight {
      img {
        max-width: 444px;
        width: 100%;
        height: 287px;
      }

      .chickenProteinInformationCard {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin: 15px 0;
        img {
          width: 16px;
          height: 16px;
        }
        p {
          color: #54595f;
          font-size: 18px;
          line-height: 1.2em;
        }
      }
    }
  }
  .chickenProtein {
    padding: 70px 0;
    .chickenProteinTable {
      margin: 30px 0;
      table {
        width: 100%;
      }
    }
    h5 {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
    }
  }
  .biologicalProperties {
    margin: 0 0 100px 0;
    .biologicalPropertiesLine {
      display: grid;
      justify-content: center;
    }
    .biologicalPropertiesImg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      margin: 20px 0;
    }
  }
  .mainApplications {
    margin: 70px 0;
    .mainApplicationsCard {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
      gap: 30px;
      p {
        font-family: "Open Sans", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.4em;
        margin-bottom: 20px;
      }
      ul {
        li {
          list-style: disc;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.7em;
        }
      }
    }
  }
  .applications {
    padding: 100px 0;
    p {
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      line-height: 1.4em;
      margin: 20px 0;
    }
    .usedFor {
      display: flex;
      justify-content: center;
      gap: 50px;
      ul {
        margin: 20px 20px;
        li {
          list-style: disc;
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          line-height: 1.2em;
        }
      }
    }
  }
  .generalInformation {
    p {
      font-family: "Open Sans", sans-serif;
      color: grey;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.2em;
      margin: 10px 0;
    }
    h4 {
      font-family: "Open Sans", sans-serif;
      color: grey;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.4em;
      margin-top: 20px;
    }
    .generalInformationBio {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0 0 0 50px;
      img {
        width: 20px;
        height: 16px;
      }
      p {
        font-family: "Open Sans", sans-serif;
        color: grey;
        font-size: 18px;
        font-weight: 400;
        line-height: 0.7em;
      }
    }
  }
  .productsCompositions {
    margin: 70px 0;
    .productsCompositionsLine {
      display: grid;
      justify-items: center;
    }
    .productsCompositionsTable {
      display: grid;
      justify-content: center;
      margin: 30px 0;
    }
  }
  .productsAndProperties {
    .productsAndPropertiesLine {
      display: grid;
      justify-items: center;
    }
    .productsAndPropertiesImg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 50px 0;
      gap: 30px;
    }
  }
  .productsAndOrganoleptic {
    margin-bottom: 70px;
    .productsAndOrganolepticLine {
      display: grid;
      justify-items: center;
    }
    .productsAndOrganolepticImg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 50px 0;
      gap: 30px;
      h5 {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        text-align: center;
        font-size: 24px;
        line-height: 1.8em;
        margin-top: 20px;
      }
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        text-align: center;
        font-size: 24px;
        a {
          font-weight: 700;
          color: blue;
        }
        span {
          font-weight: 700;
          color: red;
        }
      }
    }
  }
  .chickenProteinExtracts {
    margin: 100px 0;
    p {
      font-family: "Open Sans", sans-serif;
      color: #54595f;
      font-size: 20px;
      line-height: 1.4em;
      margin: 20px 0;
    }
    .chickenProteinExtracts__Cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      .chickenProteinExtracts__Card {
        max-width: 236px;
        width: 100%;
        min-height: 290px;
        padding: 10px;
        height: 100%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
          rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
          rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        img {
          max-width: 196px;
          width: 100%;
          height: 167.03px;
          margin-bottom: 15px;
        }
        .chickenProteinExtractsLine {
          display: flex;
          justify-content: center;
        }
        h6 {
          font-family: "Open Sans", sans-serif;
          font-size: 19px;
          font-weight: 600;
          text-align: center;
          line-height: 1.3em;
          margin: 10px 0 15px 0;
        }
      }
    }
  }
  .beverageText {
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    font-size: 16px;
    text-align: center;
    line-height: 1.8em;
    margin: 70px 0;
  }
  .beveragesCereals {
    margin: 100px 0;
    .beveragesCerealsCards {
      display: flex;
      justify-content: space-between !important;
      gap: 100px;
      margin: 30px 0;
      img {
        max-width: 520px;
        width: 100%;
      }
      .beveragesCerealsCard {
        width: 520px;
        padding: 15px;
        background: #48aa43;
        p {
          font-family: "Open Sans", sans-serif;
          color: #fff;
          font-size: 19px;
          font-weight: 600;
          line-height: 1.2em;
          margin: 10px 0;
        }
      }
      ul {
        li {
          list-style: disc;
          font-family: "Open Sans", sans-serif;
          color: #54595f;
          font-size: 17px;
          font-weight: 500;
          line-height: 1.2em;
          margin: 10px 0;
        }
      }
    }
  }
  .enzymesInBeverages {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin: 70px 0;
    .enzymesInBeveragesCard {
      display: flex;
      align-items: center;
      gap: 20px;
      margin: 25px 0;
      p {
        font-family: "Open Sans", sans-serif;
        color: #54595f;
        font-size: 29px;
        font-weight: 400;
        line-height: 1.2em;
        margin: 10px 0;
      }
    }
  }
  .technologicalOperations {
    margin: 70px 0;
    .technologicalOperationsImg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      margin: 30px 0;
    }
  }
  .beveragesBasedCereals {
    margin: 70px 0;
    .beveragesBasedCerealsCard {
      margin: 50px 0;
      display: flex;
      align-items: center;
      gap: 30px;
      width: 100%;
      table {
        width: 100%;
        p {
          margin-top: 10px;
        }
      }
      img {
        max-width: 275px;
        width: 100%;
        height: 348px;
      }
    }
  }
  .enzymePreparations {
    .enzymeTable {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 100px 0;
    }
  }
  .propertiesOfGrain {
    .propertiesOfGrainCards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin: 30px 0;
      .propertiesOfGrainCard {
        max-width: 236px;
        width: 100%;
        height: 327px;
        img {
          max-width: 234px;
          width: 100%;
          height: 123px;
        }
        h6 {
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          font-weight: 600;
          text-transform: uppercase;
          margin: 20px 0;
        }
        ul {
          margin: 0 0 0 30px;
          li {
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4em;
            list-style: disc;
          }
        }
      }
    }
  }
  .baristaWhipped {
    padding: 40px 0;
    .baristaWhippedCards {
      display: flex;
      justify-content: space-between;
      padding: 40px 0;
      gap: 30px;
      // align-items: center;
      img {
        max-width: 475px;
        width: 100%;
        height: 296px;
      }
      h5 {
        color: #000;
        font-size: 30px;
        font-weight: 600;
        text-transform: uppercase;
        font-style: italic;
        text-align: center;
        line-height: 1.2em;
      }
      .baristaWhippedCardsLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        .baristaWhippedCardLogo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          margin: 30px 0;
          img {
            width: 20px;
            height: 20px;
          }
          p {
            font-family: "Open Sans", sans-serif;
            font-size: 20px;
            text-transform: uppercase;
            margin-right: 20px;
          }
        }
      }
      span {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        line-height: 1.5em;
      }
    }
  }
  .processLines {
    margin: 70px 0;
    .processLinesCards {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
      img {
        max-width: 285px;
        width: 100%;
      }
      ul {
        margin: 30px 0 0 40px;
        li {
          font-family: "Open Sans", sans-serif;
          color: #54595f;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4em;
          list-style: disc;
        }
      }
    }
  }
  .placementProcessLine {
    margin: 0 0 100px 0;
    .placementProcessLineImg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0;
      gap: 20px;
    }
  }
  .novozymesBeveragePage {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin: 50px 0;
    .company-leader-text {
      margin: 35px 0;
      img {
        width: 16px;
        height: 16px;
        margin-top: 7px;
      }
      p {
        font-size: 19px;
        line-height: 1.4em;
        margin-bottom: 30px;
      }
    }
  }
  .liquidFoods {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    gap: 30px;
    .liquidFoodsLeaderCard {
      display: flex;
      justify-items: center;
      // align-items: center;
      gap: 20px;
      margin: 30px 0;
      img {
        max-width: 183px;
        width: 100%;
        height: 193px;
      }
      p {
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        line-height: 1.8em;
      }
    }
    .liquidFoodsCard {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 25px;
      align-items: center;
      img {
        max-width: 171px;
        width: 100%;
        height: 171px;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 5px;
      }
    }
  }
  .bioFoodProduction {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin: 70px 0;
    // align-items: center;
    .bioFoodProductionImg {
      display: grid;
      justify-items: center;
      img:first-child {
        max-width: 150px;
        width: 100%;
        height: 137px;
      }
      img:nth-child(3) {
        max-width: 400px !important;
        height: 203px;
      }
    }
    .bioFoodProductionText {
      p {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.2em;
        margin: 20px 0;
      }
      ul {
        margin: 20px;
        li {
          font-size: 16px;
          list-style: disc;
          font-weight: 400;
          line-height: 1.2em;
          margin: 20px 0;
        }
      }
    }
  }
  .enzymesAreFor {
    margin: 100px 0;
    .enzymesAreForHeat {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      gap: 120px;
      img {
        max-width: 267px;
        width: 100%;
        height: 245px;
      }
      p {
        font-size: 18px;
        line-height: 1.4em;
      }
      ul {
        margin: 30px 0 0 20px;
        li {
          font-size: 18px;
          line-height: 1.8em;
          list-style: disc;
        }
      }
    }
    .enzymesAreForCard {
      background: #47aa42;
      padding: 20px;
      margin-bottom: 20px;
      p {
        font-family: "Open Sans", sans-serif;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4em;
      }
    }
    span {
      font-family: "Open Sans", sans-serif;
      color: #54595f;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.2em;
    }
  }
  .proteinHydrolyzatesAreFor {
    margin: 100px 0;

    .proteinHydrolyzatesAreForImg {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin: 20px 0;
      img {
        max-width: 150px;
        width: 100%;
        height: 137px;
      }
      p {
        font-family: "Open Sans", sans-serif;
        color: #54595f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5em;
      }
    }
    .proteinHydrolyzatesAreForCards {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      img {
        max-width: 295px;
        width: 100%;
        height: 362px;
      }
      .proteinHydrolyzatesAreForCard {
        background: #f7a11a;
        padding: 20px;
        margin-bottom: 30px;
        p {
          font-family: "Open Sans", sans-serif;
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.5em;
        }
      }
      span {
        font-family: "Open Sans", sans-serif;
        color: #54595f;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2em;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        color: #54595f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em;
        margin-top: 50px;
        a {
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
  }
  .biosolutions-ForManufacturing {
    margin: 70px 0;
    .foodConcentrates {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      gap: 40px;
      img {
        max-width: 369px;
        width: 100%;
        height: 246px;
      }
      ul {
        li {
          p {
            font-family: "Open Sans", sans-serif;
            color: #54595f;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.6em;
          }
          font-family: "Open Sans", sans-serif;
          color: #54595f;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.6em;
          list-style: disc;
        }
      }
    }
    .foodConcentratesText {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      p {
        font-family: "Open Sans", sans-serif;
        color: #54595f;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4em;
        max-width: 500px;
        width: 100%;
      }
      .foodConcentratesTextCard {
        background: #f7a11a;
        padding: 20px;
        p {
          font-family: "Open Sans", sans-serif;
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.4em;
        }
      }
    }
  }
  .fermentationProteinsFood {
    margin: 130px 0;

    .fermentationProteinsFoodText {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      gap: 50px;
      margin: 30px 0;
      .proteinsFoodTextLeft {
        max-width: 401px;
        width: 100%;
        img {
          max-width: 400px;
          width: 100%;
        }
        p {
          font-family: "Open Sans", sans-serif;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          line-height: 1.2em;
          margin: 10px 0;
          background: #067bc2;
          padding: 20px;
        }
      }
      .proteinsFoodTextRightCard {
        border: 2px solid #48aa43;
        padding: 20px;
        margin-bottom: 20px;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        color: #000;
        font-size: 18px;
        line-height: 1.4em;
        font-weight: 700;
        margin-bottom: 10px;
        span {
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.4em;
        }
      }
    }
  }
  .juiceProductionOffer {
    margin: 80px 0;
    .juiceProductionOffer__Card {
      margin: 40px 0;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      h5 {
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 15px;
      }
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        li {
          color: #48aa43;
          font-size: 18px;
          font-weight: 600;
          text-transform: uppercase;
          line-height: 1.2em;
          list-style: disc;
        }
      }
      .juiceProductionOfferCards {
        display: flex;
        justify-content: space-between;
        // align-items: center;s
        gap: 20px;
        width: 100%;
        p {
          font-size: 17px;
          font-weight: 400;
          margin-bottom: 15px;
        }
        ul {
          display: inline-table;
          li {
            font-size: 15px;
            font-family: "Open Sans", sans-serif;
            color: gray;
            text-transform: capitalize;
          }
        }
      }
    }
  }
  .juiceProductionTechnology {
    margin: 70px 0;
    .juiceProductionTechnologyImg {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
  .pectinuxUltraColor {
    margin: 70px 0;
    .pectinuxUltraColorCards {
      margin: 40px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .pectinuxUltraColorCard {
        p {
          font-family: "Open Sans", sans-serif;
          color: #54595f;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.2em;
          margin: 10px 0;
        }
        h6 {
          font-size: 19px;
          line-height: 1.2em;
          margin-bottom: 15px;
          img {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
        }
        h4 {
          background: #48aa43;
          padding: 20px;
          color: #ffffff;
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.2em;
          margin: 10px 0;
        }
      }
    }
  }
  .berriesGrapes {
    margin: 70px 0;
    .berriesGrapesImg {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
    }
  }
  .secondaryProcessing {
    margin: 70px 0;
    .secondaryProcessingCards {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
      gap: 30px;
      img {
        max-width: 293px;
        width: 100%;
        height: 242px;
        border-radius: 10px;
      }
      p {
        font-family: "Open Sans", sans-serif;
        color: #54595f;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.2em;
        margin-bottom: 20px;
      }
      h4 {
        font-family: "Open Sans", sans-serif;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.2em;
        background: #47aa42;
        padding: 20px;
        margin-bottom: 20px;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2em;
      }
    }
  }
  .secondaryProcessingTwo {
    margin: 70px 0;
    Image {
      margin: 20px 0;
    }
  }
  .foodProducts {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    gap: 70px;
    .foodProductsImg {
      display: grid;
      justify-items: center;
      img:first-child {
        max-width: 400px;
        height: 533px;
      }
      img:nth-child(3) {
        max-width: 160px;
        height: 169px;
      }
    }
    .foodProductsCard {
      p {
        font-size: 16px;
        font-weight: 400;
        font-family: "Open Sans", sans-serif;
        line-height: 1.6em;
        margin: 30px 0;
      }
    }
    .foodProductssCard {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 25px;
      align-items: center;
      img {
        max-width: 249px;
        width: 100%;
        height: 155px;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 5px;
      }
    }
  }
  .company-competences {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin: 70px 0;
    .partners-img {
      width: 340px !important;
      height: 551px !important;
    }

    h6 {
      font-family: "Arimo", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 15px;
      line-height: 1.5em;
      max-width: 340px;
      width: 100%;
      margin-top: 20px;
    }
    .company-competences__group {
      .company-competences__text {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 30px;
        img {
          width: 100px;
          height: 100px;
        }
        p {
          font-weight: "Open Sans", sans-serif;
          font-weight: 400;
          line-height: 25px;
          font-size: 18px;
        }
      }
    }
  }
  .fermentationCards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .fermentationCard {
      padding: 10px;
      max-width: 305px;
      width: 100%;
      border: 1.5px solid #e6e6e6;
      border-radius: 5px;
      img {
        width: 283px;
        height: 188px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 15px 0;
      }
    }
  }
  .complexMoleculesIndustry {
    margin: 70px 0;
    h4 {
      color: #067bc2;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.4em;
      text-align: center;
      font-size: 20px;
      margin: 30px 0;
    }
    .complexMoleculesIndustryCards {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      img {
        max-width: 541px;
        width: 100%;
        height: 359px;
        border-radius: 5px;
      }
      h5 {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        line-height: 1.4em;
        font-size: 20px;
        margin-bottom: 20px;
        span {
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          color: #54595f;
          font-size: 24px;
          line-height: 1.4em;
        }
      }
      ul {
        margin: 0 0 0 30px;
        li {
          list-style: disc;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          line-height: 1.6em;
          font-size: 18px;
        }
      }
    }
  }
  .fermentationIndustry {
    margin: 120px 0;
    .fermentationIndustryCard {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
      gap: 20px;
      img {
        max-width: 401px;
        width: 100%;
        height: 261px;
        margin-bottom: 20px;
      }
      p {
        width: 401px;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 15px;
        line-height: 1.2em;
        text-align: center;
        background: #067bc2;
        color: #ffffff;
        padding: 10px;
      }

      ul {
        border: 1px solid #47aa42;
        margin: 20px 0 20px 30px;
        li {
          list-style: disc;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          font-size: 15px;
          padding: 10px;
        }
      }
      h5 {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.4em;
        padding: 10px;
      }
    }
  }
  .hybridProducts {
    .hybridProductsCard {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      margin: 30px 0;
      gap: 30px;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 18px;
        line-height: 1.2em;
        margin-bottom: 30px;
      }
      h5 {
        padding: 10px;
        border: 2px solid #ee3a43;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        font-size: 16px;
        line-height: 1.5em;
        margin-bottom: 20px;
      }
    }
  }
  .fermentationAminoacids {
    margin: 100px 0;

    .fermentationAminoacidsImg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
    }
    .fermentationAminoacidsText {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      margin: 30px 0;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #fff;
        font-size: 16px;
        line-height: 1.5em;
        background: #f49f0a;
        padding: 20px;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5em;
      }
    }
  }
  .biodegradablePolymers {
    margin: 70px 0;
    .biodegradablePolymersCard {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin: 30px 0;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 18px;
        line-height: 1.2em;
        margin-bottom: 50px;
      }
      h5 {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        background: #dfeed4;
        font-size: 18px;
        text-align: center;
        line-height: 1.2em;
        padding: 20px;
        margin-bottom: 20px;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        border: 1.5px solid #48aa43;
        font-size: 18px;
        text-align: center;
        line-height: 1.2em;
        padding: 20px;
        margin-bottom: 20px;
      }
      .biodegradablePolymersImg {
        width: 541px;
        height: 492px;
      }
    }
  }

  .nutreitMedia {
    margin: 70px 0;
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      color: #54595f;
      font-size: 18px;
      text-align: center;
      line-height: 1.2em;
      margin: 20px 0;
    }
    .nutreitMediaCard {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      h4 {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 18px;
        line-height: 1.2em;
        margin: 20px 0;
      }
      ul {
        margin: 0 0 0 20px;
        li {
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          font-size: 16px;
          list-style: disc;
          line-height: 1.8em;
        }
      }
      img {
        width: 400px;
        height: 270px;
        border-radius: 5px;
      }
      span {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 18px;
        line-height: 1.4em;
        margin: 20px 0;
      }
      h6 {
        background: #ffc5ab;
        padding: 30px;
      }
      h5,
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        font-size: 18px;
        line-height: 1.4em;
        margin: 20px 0;
      }
    }
  }
  .grainProcessingPage {
    margin: 70px 0;
    .grainProcessingPageCards {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
      gap: 30px;
      .grainProcessingPageCard {
        border: 2px solid #ac1821;
        padding: 20px 20px 0 20px;
        margin-bottom: 20px;
        p {
          font-family: "Open Sans", sans-serif;
          font-weight: 600;
          color: #ac1821;
          font-size: 16px;
          line-height: 1.5em;
          margin-bottom: 20px;
        }
      }
      h6 {
        color: gray;
        font-weight: 400;
      }
      h5,
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        text-align: center;
        font-size: 16px;
        line-height: 1.5em;
      }
      img {
        width: 100%;
        height: 609px;
      }
    }
  }
  .enzymativeProtein {
    margin: 70px 0;
    .enzymativeProteinCard {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
      gap: 30px;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 18px;
        line-height: 1.4em;
        margin-bottom: 30px;
      }
      h6 {
        border: 2px solid #48aa43;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        text-align: center;
        font-size: 16px;
        line-height: 1.4em;
        padding: 15px;
        margin: 30px 0 0 0;
      }
      ul {
        background: #d3e2ff;
        padding: 20px;
        margin: 10px 0;
        li {
          list-style: disc;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          font-size: 16px;
          line-height: 1.8em;
        }
      }
    }
  }
  .biotechnologChemical {
    margin: 70px 0;
    .biotechnologChemicalCard {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      gap: 30px;
      p {
        max-width: 501px;
        width: 100%;
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 16px;
        text-align: center;
        line-height: 1.2em;
        padding: 10px;
        background: #cde8b5;
        margin-top: 20px;
      }
      h5 {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 16px;
        line-height: 1.5em;
        padding: 10px;
        margin-top: 20px;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        border: 2px solid #067bc2;
        font-size: 16px;
        text-align: center;
        line-height: 1.5em;
        padding: 10px;
        margin-top: 20px;
        span {
          font-weight: 400;
        }
      }
    }
  }
  .bioChemicalIndustry {
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      color: #54595f;
      font-size: 16px;
      padding: 15px;
      text-align: center;
      line-height: 1.4em;
      margin-bottom: 20px;
      border: 2px solid #48aa43;
    }
  }
  .productionOfVitamins {
    margin: 70px 0;
    .productionOfVitaminsCard {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      margin: 40px 0;
      gap: 20px;
      p {
        padding: 20px;
        background-color: transparent;
        background-image: linear-gradient(270deg, #f9f9f9 0%, #f49f0a 100%);
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        font-size: 18px;
        line-height: 1.4em;
        margin-bottom: 20px;
        span {
          font-weight: 700;
          color: #54595f;
          font-size: 18px;
        }
      }
      h4 {
        border: 1px solid #48aa43;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 15px;
        line-height: 1.4em;
        margin-top: 50px;
        padding: 10px;
        span {
          font-weight: 400;
          color: #54595f;
          font-size: 18px;
        }
      }
      h2 {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 15px;
        text-align: center;
        line-height: 1.4em;
        padding: 10px;
        margin-bottom: 40px;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        font-size: 15px;
        text-align: center;
        line-height: 1.4em;
        padding: 10px;
        text-align: center;
      }
    }
  }
  .collapse {
    margin: 0 0 70px 0;
    .collapsePanel {
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        font-size: 16px;
        line-height: 1.7em;
        margin-bottom: 20px;
        a {
          font-weight: 700;
          color: #000;
          cursor: pointer;
        }
      }
      ul {
        margin: 0 0 0 40px;
        li {
          list-style: disc;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          font-size: 16px;
          line-height: 1.7em;
          margin-bottom: 20px;
        }
      }
      h2 {
        list-style: disc;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 18px;
        line-height: 1.7em;
        margin-bottom: 20px;
      }
      .ant-collapse-header {
        color: #fff;
        background: #067bc2;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.2em;
      }
    }
  }
  .collopseCard {
    margin: 70px 0 10px 0;
    h3 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      color: #54595f;
      font-size: 22px;
      line-height: 1.2em;
      margin-bottom: 20px;
    }
    ul {
      margin: 30px 0 0 40px;
      li {
        list-style: disc;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        font-size: 18px;
        line-height: 1.6em;
        a {
          color: blue;
          cursor: pointer;
        }
      }
    }
  }
  .newsCards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 50px;
    margin-bottom: 40px;
    h2 {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      text-transform: inherit;
      font-size: 22px;
      line-height: 1.2em;
      color: #000;
      margin: 15px 0;
    }
    p {
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.7em;
    }
    img {
      max-width: 400px;
      width: 100%;
    }
  }
  .grainCardTable {
    table {
      tr {
        td {
          color: #fff;
          text-align: center;
          font-size: 18px;
        }
        th {
          font-family: "Open Sans", sans-serif;
          color: #000;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.5em;
          margin: 10px 0;
          border: 1px solid #dddddd;
          color: #000;
          background: #f9f9f9;
          p {
            color: #000;
            font-size: 18px;
            font-weight: 500;
            margin: 0 0 5px 0;
          }
          i {
            color: #000;
            font-size: 18px;
            font-weight: 400;
            margin: 10px 0;
          }
        }
      }

      .tableCenter {
        th {
          background: none;
          border: 1px solid #dddddd;
        }
        center {
          color: #000;
          font-size: 18px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;
          margin: 10px 0;
        }
      }
    }
  }
  .productsChickenProtein {
    .productsChickenProteinTable {
      margin: 50px 0;
      table {
        tr {
          td {
            padding: 10px 50px;
          }
        }
      }

      ul {
        margin: 0 0 0 60px;
        li {
          list-style: disc;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          font-size: 16px;
          line-height: 1.5em;
        }
      }
    }
  }
  .keyApplications {
    margin: 70px 0;
    p {
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      margin: 20px 0;
      line-height: 1.7em;
    }
  }
  .imgPageHalal {
    margin: 50px 0;
    p {
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      margin: 20px 0;
      line-height: 1.7em;
      margin-bottom: 60px;
    }
    .imgHalal {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .injiniringText {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    gap: 40px;
    p {
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: 600;
      margin: 20px 0;
      line-height: 1.3em;
    }
    ul {
      li {
        list-style: disc;
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 400;
        margin: 20px 0;
        line-height: 1.3em;
      }
    }
  }
  .universalInjLines {
    margin: 70px 0;
    .universalInjLinesCard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      margin: 40px 0;
      img {
        width: 100%;
        height: 489px;
      }
    }
  }
  .universalProcessLines {
    margin: 70px 0;
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 16px;
      line-height: 1.5em;
      margin: 20px 0;
      span {
        font-weight: 700;
      }
    }
    .universalProcessLinesCards {
      margin: 50px 0;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 40px;
      li {
        list-style: disc;
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 18px;
        line-height: 1.2em;
        margin: 20px 0;
      }
    }
  }
  .elementor-widget-wraP {
    margin: 70px 0;
    .elementor-element-populated {
      display: flex;
      justify-content: space-between;
      gap: 40px;
      align-items: center;
      margin: 30px 0;
      img {
        max-width: 216px;
        width: 100%;
        height: 341px;
      }
      ul {
        li {
          list-style: decimal;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          font-size: 16px;
          line-height: 1.8em;
        }
      }
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 16px;
        line-height: 1.7em;
        margin: 40px 0;
      }
    }
  }
  .roteaseEnzyme {
    margin: 70px 0;
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 18px;
      line-height: 1.6em;
      margin: 20px 0;
    }
    .roteaseEnzymeCard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        color: #54595f;
        font-size: 20px;
        line-height: 1.5em;
        margin-bottom: 20px;
      }
    }
  }
  .proteasePprtfolio {
    margin: 70px 0;
    h5 {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 26px;
      line-height: 1.2em;
      margin-bottom: 20px;
      text-align: center;
    }
    .proteasePprtfolioImg {
      display: grid;
      justify-content: center;
    }
  }
  .proteasesPurpose {
    margin: 70px 0;
    .grainCardTable {
      margin: 30px 0;
    }
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      color: #54595f;
      font-size: 18px;
      line-height: 1.2em;
      margin-bottom: 20px;
    }
  }
  .elementor-element {
    margin: 70px 0;
    span {
      color: red;
    }
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      text-align: center;
      font-size: 18px;
      line-height: 1.5em;
      margin: 20px 0;
    }
    .elementor-section {
      display: flex;
      justify-content: space-between;
      gap: 40px;
      img {
        border-radius: 10px;
      }
      h5 {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 18px;
        line-height: 1.5em;
        margin: 20px 0;
      }
      i {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 14px;
        line-height: 1.5em;
        margin: 20px 0;
      }
    }
  }
  .elementor-heading {
    margin: 70px 0;
    .elementor-widget-wrap {
      display: flex;
      justify-content: space-between;
      gap: 40px;
      margin: 40px 0;
      img {
        border-radius: 10px;
        max-width: 362px;
        width: 100%;
        height: 240px;
      }
      ul {
        li {
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          color: #54595f;
          font-size: 16px;
          line-height: 1.2em;
          margin-bottom: 20px;
          list-style: disc;
        }
      }
    }
  }
  .elementor-widget-heading {
    margin: 70px 0;

    h3:nth-child(3) {
      color: #0689ce;
    }
    .elementor-widget-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 24px;
        line-height: 1.2em;
        margin: 20px 0;
      }
    }
  }
  .elementor-container {
    margin: 70px 0;

    .elementor-container-Card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 40px 0;
      ul {
        li {
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          list-style: disc;
          font-size: 18px;
          line-height: 1.5em;
        }
      }
    }
  }
  .absolute-industry {
    margin: 70px 0 170px 0;
    .absolute-industry-card {
      display: flex;
      justify-content: space-between;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        font-size: 18px;
        line-height: 1.6em;
        margin: 20px 0;
      }

      ul {
        li {
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          font-size: 18px;
          line-height: 1.6em;
          list-style: disc;
        }
      }
    }
  }
  .vigetableUl {
    margin: 0 0 0 50px;
    li {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 16px;
      list-style: decimal;
      margin-top: 20px;
    }
  }
  .vigitableProtein {
    margin: 70px 0 70px 0;
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      text-align: center;
      font-size: 18px;
      line-height: 1.5em;
      margin: 20px 0;
    }
    .vigitableProteinCards {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
      gap: 30px;
      .vigitableProteinCard {
        padding: 15px;
        background: #fefcdd;
        h5 {
          font-family: "Open Sans", sans-serif;
          font-weight: 600;
          color: #54595f;
          font-size: 22px;
          line-height: 1.2em;
          margin: 20px 0;
        }
        ul {
          margin: 0 0 0 20px;
          li {
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            list-style: disc;
            color: #54595f;
            font-size: 16px;
            line-height: 1.7em;
          }
        }
      }
    }
  }
  .plantProteins {
    margin: 70px 0;

    .plantProteinsCards {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
      .plantProteinsCard {
        p {
          font-size: 18px;
          font-weight: 600;
          line-height: 1.4em;
          margin: 20px 0;
          text-align: center;
        }
        ul {
          margin: 20px 0;
          li {
            font-family: "Open Sans", sans-serif;
            font-weight: 600;
            color: #54595f;
            font-size: 18px;
            list-style: disc;
            line-height: 1.7em;
          }
        }
        img:nth-child(3) {
          width: 418px;
          height: 298px;
        }
        img:nth-child(1) {
          width: 711px;
          height: 383px;
        }
      }
    }
  }
  .enzymaticPlantProtein {
    margin: 120px 0;
    p {
      text-align: center;
      padding: 15px;
      background: #cfe2ff;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 22px;
      text-align: center;
      line-height: 1.2em;
      margin: 30px 0;
    }
    .enzymaticPlantProteinTable {
      display: grid;
      justify-self: center;
    }
    h5 {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 18px;
      text-align: center;
      line-height: 1.2em;
      margin: 30px 0;
    }
  }
  .elementorPlant {
    .elementorPlantCards {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 30px;
      margin: 30px 0;
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        text-align: center;
        font-size: 18px;
        line-height: 1.5em;
        margin: 30px 0 0 0;
        span {
          font-weight: 700;
        }
      }
    }
  }
  .productionFlowChart {
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 18px;
      text-align: center;
      line-height: 1.5em;
      background: #fbe5d6;
      padding: 20px 0;
      margin: 20px 0;
    }
  }
  .naturalProteinExtracts {
    margin: 80px 0;
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 16px;
      line-height: 1.4em;
      margin: 20px 0;
      span {
        font-weight: 700;
      }
    }
    .proteinExtractsCardsOne {
      display: flex;
      justify-content: space-between;
      gap: 40px;
      ul {
        h5 {
          font-family: "Open Sans", sans-serif;
          font-weight: 600;
          color: #54595f;
          font-size: 18px;
          line-height: 1.7em;
          margin: 20px 0;
        }
        li {
          list-style: disc;
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          color: #54595f;
          font-size: 18px;
          line-height: 1.4em;
        }
      }
    }
    .proteinExtractsCardsTwo {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin: 30px 0;
      h5 {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 18px;
        line-height: 1.2em;
        background: #ffde00;
        padding: 20px;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 18px;
        line-height: 1.2em;
        border: 1px solid #ee3a43;
        padding: 20px;
      }
    }
  }
  .fishHb {
    margin: 80px 0;
  }
  .fishProcessingIndustry {
    margin: 80px 0;
    h5 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      color: #f4a20a;
      font-size: 24px;
      text-align: center;
      line-height: 1.2em;
      margin: 20px 0;
    }
    .fishProcessingIndustryCards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      .industryCardsOne {
        p {
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          font-size: 20px;
          line-height: 1.2em;
          margin: 20px 0;
        }
        ul {
          li {
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            list-style: disc;
            font-size: 18px;
            line-height: 1.2em;
            margin: 20px 0;
          }
        }
      }
      .industryCardsTwo {
        background: #f4a20a;
        padding: 20px;
        p {
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          color: #ffffff;
          font-size: 20px;
          line-height: 1.2em;
          margin: 20px 0;
        }
        ul {
          margin: 0 0 0 20px;
          li {
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            color: #ffffff;

            list-style: disc;
            font-size: 18px;
            line-height: 1.2em;
            margin: 20px 0;
          }
        }
      }
    }
  }
  .universalTechno {
    margin: 70px 0;
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 18px;
      text-align: center;
      line-height: 1.2em;
      margin: 20px 0;
    }
  }
  .boneBroth {
    margin: 70px 0;
    .boneBrothCards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      margin: 30px 0;
      img {
        max-width: 393px;
        width: 100%;
        height: 254px;
      }
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 18px;
        line-height: 1.7em;
      }
    }
  }
  .proteasesProducers {
    margin: 120px 0;
    .proteasesProducersCard {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
      gap: 20px;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        color: #54595f;
        font-size: 20px;
        line-height: 1.2em;
        margin: 20px 0;
      }
      ul {
        li {
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          list-style: disc;
          color: #54595f;
          font-size: 16px;
          line-height: 1.2em;
          margin: 20px 0;
        }
      }

      img {
        max-width: 393px;
        width: 100%;
        height: 261px;
      }
    }
  }
  .descriptionProduct {
    margin: 120px 0;
    .descriptionProductCards {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      gap: 30px;
      .descriptionProductImg {
        width: 295px;
        height: 423px;
      }
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 18px;
        line-height: 1.4em;
        margin: 20px 0;
        span {
          font-weight: 400;
        }
      }
      .descriptionProductCard {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h5 {
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          color: #54595f;
          font-size: 20px;
          line-height: 1.2em;
          margin: 20px 0;
        }
        p {
          padding: 10px;
          border: 1px solid #ee3a43;
        }
      }
    }
  }
  .bulonFerment {
    margin: 120px 0;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    img {
      max-width: 100%;
      width: 393px;
      height: 517px;
    }
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 20px;
      line-height: 1.2em;
      margin: 20px 0;
    }
    ul {
      margin: 0 0 0 20px;
      li {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 18px;
        list-style: disc;
        line-height: 1.9em;
      }
    }
    .bulonFermentCard {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-top: 30px;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 18px;
        line-height: 1.2em;
      }
      img {
        max-width: 100%;
        width: 66px;
        height: 66px;
      }
    }
  }
  .poultryMeat {
    margin: 80px 0;
    .poultryMeatCards {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        font-size: 22px;
        line-height: 1.2em;
        margin: 20px 0;
      }
      .poultryMeatCard:nth-child(2) {
        background-color: transparent;
        background-image: linear-gradient(270deg, #ffffff 0%, #a9e2a7 100%);
      }
      .poultryMeatCard:nth-child(3) {
        background-color: transparent;
        background-image: linear-gradient(270deg, #ffffff 0%, #ffefad 100%);
      }
      .poultryMeatCard:nth-child(4) {
        background-color: transparent;
        background-image: linear-gradient(270deg, #ffffff 0%, #f9c2c4 100%);
      }
      h5 {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 22px;
        line-height: 1.6em;
        margin: 20px 0;
        padding: 15px;
        h4 {
          font-weight: 700;
        }
      }
    }
  }
  .poultryProcessingExtract {
    margin: 80px 0;
    .processingExtractCards {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin: 40px 0;
      .processingExtractCard {
        padding: 10px;

        p {
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          font-size: 18px;
          line-height: 1.2em;
          margin: 20px 0;
        }
        h4 {
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          color: #54595f;
          font-size: 18px;
          line-height: 1.2em;
          margin: 20px 0;
        }
      }
      .processingExtractCard:nth-child(1) {
        background-color: transparent;
        background-image: linear-gradient(270deg, #ffffff 0%, #ff6250 100%);
      }
      .processingExtractCard:nth-child(2) {
        background-color: transparent;
        background-image: linear-gradient(270deg, #ffffff 0%, #ffc777 100%);
      }
      .processingExtractCard:nth-child(3) {
        background-color: transparent;
        background-image: linear-gradient(270deg, #ffffff 0%, #ff6250 100%);
      }
    }
    .processingExtractCardsTwo {
      padding: 10px;
      border: 1px solid #f7a11a;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 16px;
        line-height: 1.5em;
        span {
          font-weight: 400;
        }
      }
    }
    .processingExtractCardsThree {
      display: flex;
      justify-content: space-between;
      background: #f7a11a;
      margin: 30px 0;
      padding: 10px;
      gap: 30px;
      img {
        max-width: 239px;
        width: 100%;
        height: 160px;
      }
      ul {
        p {
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          color: #000;
          font-size: 16px;
          line-height: 1.5em;
        }
        li {
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          color: #ffffff;
          font-size: 16px;
          line-height: 2em;
          list-style: disc;
        }
      }
    }
  }
  .horecaIndustry {
    margin: 80px 0;
    .horecaIndustryCards {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
      gap: 30px;
      img {
        max-width: 464px;
        width: 100%;
        height: 450px;
      }
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 18px;
        background: #ffdbd8;
        padding: 20px;
        line-height: 1.2em;
        margin: 0px 0 20px 0;
      }
      ul {
        margin: 0 0 30px 20px;
        li {
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          list-style: disc;
          line-height: 1.9em;
          font-size: 16px;
        }
      }
    }
  }
  .cultivatedFoodProteins {
    margin: 80px 0;
    .cultivatedFoodProteinsCards {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin: 30px 0;
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: #ffff;
        line-height: 1.2em;
        padding: 15px;
        margin: 20px 0;
        background: #067bc2;
      }
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.7em;
        span {
          font-weight: 700;
        }
      }
      h5 {
        border: 1.5px solid #48aa43;
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.7em;
        padding: 15px;
        margin: 20px 0;
      }
    }
  }
  .meatProduction {
    margin: 70px 0;
    .meatProductionCard {
      display: flex;
      justify-content: space-between;
      gap: 40px;
      margin: 0 0 20px 0;
      img {
        width: 100%;
        max-width: 286px;
        height: 191px;
      }
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 18px;
        line-height: 1.5em;
        margin: 20px 0;
      }
    }

    h5 {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.7em;
    }
    ul {
      margin: 0 0 0 20px;
      li {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 18px;
        list-style: disc;
        line-height: 1.8em;
      }
    }
  }
  .dairyProduction {
    margin: 80px 0;
    h4 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      color: #ee3a43;
      text-align: center;
      font-size: 22px;
      line-height: 1.2em;
      margin: 20px 0;
    }
    .dairyProductionCard {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 70px;
      img {
        border-radius: 10px;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2em;
        margin: 20px 0;
      }
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 18px;
        line-height: 1.5em;
        margin-bottom: 20px;
      }
      ul {
        margin: 0 0 0 20px;
        li {
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          color: #54595f;
          font-size: 18px;
          list-style: disc;
          line-height: 1.8em;
        }
      }
    }
  }
  .seafoodProduction {
    margin: 150px 0;
    h4 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      color: #ee3a43;
      font-size: 22px;
      text-align: center;
      line-height: 1.2em;
      margin: 20px 0;
    }
    .seafoodProductionImg {
      display: flex;
      justify-content: center;
    }
    ul {
      margin: 20px 0 0 20px;
      li {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        color: #54595f;
        font-size: 18px;
        list-style: disc;
        line-height: 1.2em;
        margin: 20px 0;
      }
    }
  }
  .fatsAndOil {
    margin: 80px 0;
    .fatsAndOilCards {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin: 30px 0;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #ee3a43;
        font-size: 18px;
        line-height: 1.4em;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.6em;
        margin: 20px 0;
      }
      h4 {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 18px;
        line-height: 1.2em;
        margin: 20px 0;
      }
    }
  }
  .hybridProductsProtein {
    margin: 150px 0;
    .hybridProductsCards {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      gap: 30px;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 18px;
        line-height: 1.4em;
        margin-bottom: 20px;
      }
      h4 {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        border: 1px solid #ee3a43;
        font-size: 16px;
        padding: 15px;
        line-height: 1.4em;
        margin-bottom: 20px;
      }
    }
  }
  .goodFoodCards {
    display: flex;
    justify-content: space-between;
    margin: 80px 0;
    gap: 30px;
    p {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      color: #54595f;
      font-size: 18px;
      line-height: 1.5em;
      margin: 20px 0 20px 0;
    }
    img {
      max-width: 341px;
      width: 100%;
      height: 422px;
    }
  }
  .lysineProduction {
    margin: 80px 0;
    .lysineProductionCard {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      img {
        max-width: 239px;
        width: 100%;
        height: 236px;
      }
      p {
        max-width: 236px;
        width: 100%;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        font-size: 16px;
        text-align: center;
        line-height: 1.2em;
        margin: 0px 0 20px 0;
      }
      .lysineProductionCardImg {
        max-width: 466px;
        width: 100%;
        height: 480px;
      }
      h4 {
        background-color: transparent;
        background-image: linear-gradient(270deg, #ffffff 0%, #f49f0a 100%);
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        max-width: 357px;
        width: 100%;
        font-size: 16px;
        padding: 15px;
        line-height: 1.2em;
        margin: 0px 0 20px 0;
      }
    }
  }
  .productionOfLactic {
    margin: 80px 0;

    .productionOfLacticCards {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      img {
        max-width: 239px;
        width: 100%;
        height: 189px;
      }
      p {
        margin: 30px 0;
        text-align: center;
      }
      .productionOfLacticTwo {
        max-width: 428px;
        width: 100%;
        h4 {
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          color: #54595f;
          font-size: 18px;
          padding: 15px;
          background-color: transparent;
          background-image: linear-gradient(270deg, #ffffff 0%, #f49f0a 100%);
          line-height: 1.2em;
          margin: 0px 0 20px 0;
        }
        p,
        h6 {
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          color: #54595f;
          font-size: 18px;
          line-height: 1.2em;
          margin: 0px 0 20px 0;
        }
        h5 {
          border: 1px solid #48aa43;
          padding: 15px;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          font-size: 16px;
          line-height: 1.2em;
          margin-bottom: 30px;
        }
      }
      .productionOfLacticCardsImg {
        max-width: 403px;
        width: 100%;
        height: 540px;
      }
    }
  }
}
// PROTEINCARD

.proteinTechnological {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 50px 0;
  img {
    max-width: 354px;
    width: 100%;
    height: 229px;
  }
  p {
    font-size: 18px;
    line-height: 1.8em;
  }
}

.footer {
  z-index: 11;
  text-align: center;
  padding: 20px;
}
.footer-line {
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
}

.card {
  .grainFeedBases {
    margin: 80px 0;

    h4 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      color: #ee3a43;
      font-size: 22px;
      text-align: center;
      line-height: 1.2em;
      margin: 20px 0 20px 0;
    }
    .grainFeedBasesCards {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      img {
        max-width: 380px;
        width: 100%;
        border-radius: 10px;
        height: 306px;
      }
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: #54595f;
        font-size: 18px;
        line-height: 1.2em;
        margin: 0px 0 20px 0;
      }
      ul {
        margin: 0 0 0 20px;
        li {
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #54595f;
          font-size: 16px;
          list-style: disc;
          line-height: 1.6em;
        }
      }
      h5 {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #ffffff;
        background: #47aa42;
        font-size: 18px;
        padding: 15px;
        line-height: 1.5em;
        margin: 20px 0;
      }
      h6 {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        font-size: 18px;
        line-height: 1.2em;
        margin: 0px 0 20px 0;
      }
    }
  }
  .economyGrain {
    margin: 80px 0;
    h4 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      color: #ee3a4f;
      font-size: 20px;
      line-height: 1.2em;
      margin: 20px 0 20px 0;
    }
    .economyGrainCards {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2em;
        margin: 0px 0 20px 0;
      }
      ul {
        margin: 0 0 0 20px;
        li {
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          font-size: 16px;
          list-style: disc;
          line-height: 1.2em;
          margin: 0px 0 20px 0;
        }
      }
      .economyGrainCardsUl {
        background: #47aa42;
        padding: 15px;
        ul {
          margin: 0 0 0 20px;
          li {
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            color: #ffffff;
            font-size: 16px;
            list-style: disc;
            line-height: 1.2em;
            margin: 0px 0 20px 0;
          }
        }
      }
      h5 {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2em;
        margin: 20px 0 20px 0;
      }
      img {
        width: 381px;
        height: 461px;
        border-radius: 10px;
      }
      h6 {
        background: #f49f0a;
        padding: 15px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        line-height: 1.2em;
        margin: 20px 0 20px 0;
      }
    }
  }
  .projectImplementation {
    margin: 80px 0;
    .projectImplementationCards {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin: 30px 0;
    }
    .projectImplementationCard {
      padding: 10px;
      margin-bottom: 30px;
      p {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #54595f;
        font-size: 18px;
        line-height: 1.5em;
      }
    }
    .projectImplementationCard:nth-child(1) {
      border: 1px solid #067bc2;
    }
    .projectImplementationCard:nth-child(2) {
      border: 1px solid #f49f0a;
    }
    .projectImplementationCard:nth-child(3) {
      border: 1px solid #48aa43;
    }
  }
}

.modalLink {
  button {
    background: none;
    font-size: 16px;
    margin-bottom: 20px !important;
    transition: all 0.5s ease;
    padding: 5px;
    width: 100%;
    &:hover {
      background: #f4a20a;
    }
  }
}

.footerOpen {
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 24px;
      font-style: italic;
    }
  }

  img {
    width: 70px;
    height: 70px;
  }
}

.grainBioXim {
  display: none !important;
}

.contact-form {
  .text-center {
    text-align: center !important;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 5px;
    margin: 40px auto;
    padding: 5px;
    box-shadow: 0 2px 2px #ccc;

    .form-group {
      margin: 10px auto;
      width: 90%;

      .form-control {
        display: block;
        margin: 5px 0;
        width: 100%;
        height: 20px;

        &:focus {
          outline: none;
        }
      }

      .error {
        color: #ff3f34;
        display: block;
        // text-align: center;
        padding: 5px 0;
      }
    }
  }

  button {
    color: #fff;
    background-color: #130f40;
    padding: 10px 35px;
    margin: 20px auto;
    display: block;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
  }
}
