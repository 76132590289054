@media screen and (max-width: 1450px) {
  .header-logo {
    padding: 0 15px;
  }
}
@media screen and (max-width: 1350px) {
  .nav-link {
    display: none !important;
  }
  .novozymesLogo {
    display: none !important;
  }
  .openModal {
    display: block;
    .openModal-button {
      background: none;
      border: none;
    }
    .openModal-Icon {
      font-size: 32px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .card {
    padding: 0 15px;
  }
  .plantProteinsCards {
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center !important;
    img {
      width: 100% !important;
    }
  }
  .elementorPlantCards {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    img {
      width: 100% !important;
    }
    h6 {
      text-align: start !important;
    }
  }
}
@media screen and (max-width: 1024px) {
  .cards-card {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    justify-content: center !important;
    .cards-card__One {
      max-width: none !important;
      width: 100% !important;
      img {
        max-width: none !important;
        width: 100% !important;
      }
    }
  }
  .cardsGrain {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    justify-content: center;
    .cardsCard {
      min-height: 200px !important;
    }
  }
  .mainGrainProducts {
    flex-wrap: wrap;
    justify-content: center !important;
    .grainProductsImg {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 15px;
    }
  }
  .tableHoney {
    flex-wrap: wrap;
    justify-content: center !important;
    gap: 20px;
    img {
      width: 300px;
      height: 200px;
      padding: 0 15px !important;
    }
  }
  .recyclingPage {
    .recylingImage {
      flex-wrap: wrap;
      justify-content: center !important;
    }
    .recylingText {
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      .recylingTextGreen {
        padding: 15px !important;
      }
    }
  }
  .balanceCorn {
    .recylingImage {
      flex-wrap: wrap;
      justify-content: center !important;
      img {
        width: 100%;
        height: 200px !important;
        object-fit: cover;
      }
    }
  }
  .fermentPage {
    .fermentTable {
      max-width: 614px;
      width: 100%;
      table:nth-child(1) {
        tr {
          align-items: center;
        }
      }
    }
  }
  .bioPropertiesFlex {
    flex-wrap: wrap;
    gap: 20px;
  }
  .aminoAcidsCard {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .proteinExtractsCardsOne {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .universalProcessLines {
    .universalProcessLinesCards {
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
  }
  .spirtImg {
    flex-wrap: wrap;
    justify-content: center;
  }
  .processDiagramCards {
    flex-wrap: wrap;
    justify-items: center !important;
    .processDiagramCard {
      width: 100% !important;
      padding: 10px !important;
      height: 100% !important;
    }
  }
  .alcoholPreparationImg {
    flex-wrap: wrap;
    justify-content: center !important;
    gap: 20px;
    width: 100%;
  }
  .incraseReduction {
    flex-wrap: wrap;
    justify-content: center !important;
    width: 100%;
  }
  .alcoholFermentationCard {
    flex-wrap: wrap;
    img {
      width: 100%;
    }
  }
  .engineeringEquipmentCard {
    flex-wrap: wrap;
  }
  .glikoGagCard {
    flex-wrap: wrap;
  }
  .gagExtractionMain {
    flex-wrap: wrap;
  }
  .generalShemeCards {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .chickenProteinInformation {
    flex-wrap: wrap;
  }
  .biologicalPropertiesImg {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .mainApplicationsCard {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .usedFor {
    flex-wrap: wrap;
  }
  .generalInformation {
    p {
      line-height: 25px !important;
    }
  }
  .productsAndPropertiesImg {
    flex-wrap: wrap;
  }
  .productsAndOrganolepticImg {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .chickenProteinExtracts {
    .chickenProteinExtracts__Cards {
      flex-wrap: wrap;
      justify-content: center !important;
    }
  }
  .beveragesCerealsCards {
    flex-wrap: wrap;
    justify-content: center !important;
    img {
      width: 100% !important;
    }
    .beveragesCerealsCard {
      width: 100% !important;
    }
  }
  .enzymesInBeverages {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .beveragesBasedCerealsCard {
    flex-wrap: wrap;
  }
  .propertiesOfGrainCards {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .baristaWhippedCards {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .processLinesCards {
    flex-wrap: wrap;
    justify-content: center !important;
    gap: 20px;
  }
  .novozymesBeveragePage {
    flex-wrap: wrap;
    img {
      width: 100%;
    }
  }
  .liquidFoods {
    flex-wrap: wrap;
    justify-content: center !important;
    .liquidFoodsLeaderCard {
      flex-wrap: wrap;
      justify-content: center !important;
    }
    .liquidFoodsImg {
      width: 100%;
    }
  }
  .fermentationProteinsFoodText {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .juiceProductionOffer__Card {
    flex-wrap: wrap;
    justify-content: center !important;
    ul {
      flex-wrap: wrap !important;
      flex-direction: column;
    }
  }
  .juiceProductionOfferCards {
    flex-wrap: wrap;
    justify-content: center !important;
    ul {
      margin: 0 0 0 20px;
    }
  }
  .pectinuxUltraColorCards {
    flex-wrap: wrap;
    justify-content: center !important;
    img {
      width: 100%;
    }
  }
  .berriesGrapesImg {
    flex-wrap: wrap;
    .berriesGrapesImgTwo {
      width: 100%;
    }
  }
  .secondaryProcessingCards {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .foodProducts {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .foodProductssCard {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    justify-content: center !important;
  }
  .fermentationCardsHome {
    .fermentationCards {
      flex-wrap: wrap !important;
      flex-direction: column;
    }
  }
  .complexMoleculesIndustryCards {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .fermentationIndustryCard {
    flex-wrap: wrap;
    justify-content: center !important;
    img {
      max-width: none !important ;
      width: 100% !important;
    }
    p {
      width: 100% !important;
      max-width: none !important;
    }
  }
  .hybridProductsCard {
    flex-wrap: wrap;
    img {
      width: 100% !important;
      max-width: none !important;
    }
  }
  .fermentationAminoacidsImg {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .fermentationAminoacidsText {
    flex-wrap: wrap;
  }
  .biodegradablePolymersCard {
    flex-wrap: wrap;
    .biodegradablePolymersImg {
      width: 100% !important;
      object-fit: cover;
    }
  }
  .nutreitMediaCard {
    flex-wrap: wrap;
    img {
      width: 100% !important;
      max-width: none !important;
      object-fit: cover;
    }
  }
  .grainProcessingPageCards {
    flex-wrap: wrap;
  }
  .enzymativeProteinCard {
    flex-wrap: wrap;
    justify-content: center !important;
    img {
      width: 100% !important;
    }
  }
  .biotechnologChemicalCard {
    flex-wrap: wrap;
    justify-content: center !important;
    img {
      width: 100% !important;
    }
  }
  .productionOfVitaminsCard {
    flex-wrap: wrap;
  }
  .contact {
    flex-wrap: wrap;
    justify-content: center !important;
    .contact-input {
      input {
        width: 100% !important;
      }
    }
    .contact-inputFlex {
      flex-wrap: wrap !important;
      flex-direction: column !important;
      justify-items: center !important;
    }
    .inputTextarea {
      width: 100% !important;
    }
  }
  .company-product {
    flex-wrap: wrap;
    img {
      width: 100% !important;
    }
  }
  .company-competences {
    flex-wrap: wrap;
    flex-direction: column !important;
    .partners-img {
      width: 100% !important;
    }
  }
  .partner-advertising {
    flex-wrap: wrap;
    justify-content: center !important;
    img {
      width: 100% !important;
    }
  }
  .collopseCard {
    ul {
      margin: 30px 0px !important;
      li {
        width: 100% !important;
        a {
          width: 100% !important;
        }
      }
    }
  }
  .newsCards {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    .news-Card {
      width: 100% !important;
    }
  }
  .elementor-section {
    flex-wrap: wrap !important;
    flex-direction: column !important;
    img {
      width: 100% !important;
    }
  }
  .elementor-widget-wrap {
    flex-wrap: wrap !important;
    img {
      max-width: none !important;
      object-fit: cover;
      width: 100% !important;
    }
  }
  .elementor-container-Card {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .absolute-industry-card {
    flex-wrap: wrap;
    img {
      width: 100% !important;
    }
  }
  .vigitableProteinCards {
    flex-wrap: wrap;
  }
  .fishProcessingIndustryCards {
    flex-wrap: wrap;
    justify-content: center !important;
    img {
      width: 100% !important;
    }
  }
  .boneBrothCards {
    flex-wrap: wrap;
    img {
      max-width: none !important;
      object-fit: cover;
      width: 100% !important;
    }
  }
  .proteasesProducersCard {
    flex-wrap: wrap;
    img {
      width: 100% !important;
      max-width: none !important;
      object-fit: cover;
    }
  }
  .descriptionProductCards,
  .grainFeedBasesCards,
  .descriptionProductCard,
  .economyGrainCards,
  .projectImplementationCards,
  .cultivatedFoodProteinsCards,
  .goodFoodCards,
  .lysineProductionCard,
  .productionOfLacticCards,
  .roteaseEnzymeCard {
    flex-wrap: wrap;
    justify-content: center !important;
    img {
      width: 100% !important;
      object-fit: cover;
      max-width: none !important;
    }
  }
  .bulonFerment {
    flex-wrap: wrap;
    justify-content: center !important;
    width: 100% !important;
  }
  .dairyProductionCard,
  .injiniringText,
  .universalInjLinesCard {
    flex-wrap: wrap;
    img {
      width: 100% !important;
      object-fit: cover;
      max-width: none !important;
      order: -1;
    }
  }

  .keyApplications {
    table {
      display: none !important;
    }
  }
}
@media screen and (max-width: 750px) {
  .cards-card {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    justify-items: center !important;
    .cards-card__One {
      max-width: 594px !important;
      width: 100% !important;
      img {
        max-width: 594px !important;
        width: 100% !important;
        min-height: 290px !important;
      }
    }
  }
  .cardsGrain {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    justify-items: center;
  }
  .grainProcessing {
    flex-wrap: wrap !important;
    justify-content: center !important;
  }
  .healthPage {
    .healthFlexPage {
      flex-wrap: wrap;
      gap: 20px;
      img {
        width: 100%;
        height: 200px;
      }
    }
  }
  .glutenPage {
    .glutenPageCard {
      flex-wrap: wrap;
      img {
        width: 100% !important;
        height: 200px !important;
      }
    }
  }
  .proteinTechnological {
    flex-wrap: wrap;
  }
  .proteinExtractsCardsTwo {
    flex-wrap: wrap;
  }
  .universalProcessLines {
    .universalProcessLinesCards {
      grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
      img {
        height: none;
      }
    }
  }
  .mainApplicationsCard {
    img {
      width: 100%;
      height: 300px;
    }
  }
  .enzymesInBeverages {
    p {
      font-size: 16px !important;
    }
    .enzymesInBeveragesImg {
      width: 100%;
    }
  }
  .technologicalOperationsImg {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .placementProcessLineImg {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .liquidFoodsCard {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    justify-content: center !important;
  }
  .bioFoodProduction {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .enzymesAreForHeat {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .proteinHydrolyzatesAreForImg {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .proteinHydrolyzatesAreForCards {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .foodConcentrates {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .foodConcentratesText {
    flex-wrap: wrap;
    p {
      max-width: none !important;
      width: 100% !important;
    }
    .foodConcentratesTextCard {
      width: 100% !important;
    }
  }
  .company-competences {
    flex-wrap: wrap;
    justify-content: center !important;
    h6 {
      max-width: none !important;
      width: 100% !important;
    }
  }
  .newsCards {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    .news-Card {
      .news-CardLink {
        width: 100% !important;
      }
    }
  }
  .elementorPlantCards {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .poultryMeatCards {
    flex-wrap: wrap;
  }
  .processingExtractCards,
  .processingExtractCardsThree,
  .horecaIndustryCards,
  .meatProductionCard,
  .fatsAndOilCards,
  .hybridProductsCards,
  .elementor-element-populated {
    flex-wrap: wrap;
    img {
      width: 100% !important;
      max-width: none !important;
      object-fit: cover;
    }
  }
  .seafoodProductionImg {
    img {
      width: 100% !important;
      max-width: none !important;
    }
  }
  .body {
    padding: 0 !important;
  }
}
@media screen and (max-width: 630px) {
  .grainCardTable {
    table {
      display: none !important;
    }
  }
  .relative {
    padding: 80px 0 50px 0;
    .grainBioXim {
      display: block !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .beveragesBasedCerealsCard {
    table {
      display: none;
    }
  }
  .openModal {
    img {
      display: none;
    }
  }
}
@media screen and (max-width: 500px) {
  .cardsGrain {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    justify-items: center;
    .cardsCard {
      min-height: 200px !important;
      max-width: 407px !important;
      width: 100% !important;
      img {
        max-width: 397px !important;
        width: 100% !important;
      }
    }
  }

  .enzymaticPlantProteinTable {
    display: none !important;
  }
}
@media screen and (max-width: 475px) {
  .mainImg {
    h3 {
      padding: 15px 15px !important;
      font-size: 24px !important;
    }
    img {
      height: 300px !important;
      width: 100%;
    }
  }
  .text-h3 {
    font-size: 20px !important;
  }

  .foodProductssCard {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    justify-items: center !important;
    img {
      max-width: none !important;
      height: none !important;
      width: 100% !important;
    }
  }
  .relative {
    padding: 80px 0 50px 0;
    .grainBioXim {
      display: block !important;
    }
  }
  .header-logo {
    img {
      height: 60px !important;
      width: 60px !important;
    }
    h3 {
      font-size: 24px !important;
      font-style: normal !important;
    }
  }
}
